<template>
  <div>
    <title>SIM INVENTORY ~ ADD PRODUCTION PROCESS DATA</title>
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <h1>
        Add Production Process Data
        <br />
        <h4>Please enter production process transaction data lists</h4>
        <!-- <small>Control panel</small> -->
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Dashboard</li>
      </ol>
    </section>

    <!-- Main content -->
    <section class="content">
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="box box-primary">
            <div class="box-header with-border">
              <i class="fa fa-save"></i>
              <h3 class="box-title">Save Production Process</h3>
            </div>
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Order</label>
                  <select
                    @change="viewPart()"
                    v-model="nomorproduk"
                    class="form-control"
                  >
                    <!-- <option disabled>--Please Select--</option> -->
                    <option
                      v-for="datadatalist in datalist"
                      :key="datadatalist.id"
                      :value="datadatalist.id"
                    >
                      {{datadatalist.order_number}} => {{ datadatalist.nomor_produk }}
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Part</label>
                  <select
                    @change="viewProses()"
                    v-model="partlists"
                    class="form-control"
                  >
                    <!-- <option disabled>--Please Select--</option> -->
                    <option
                      v-for="datadatapartlist in datapartlist"
                      :key="datadatapartlist.id"
                      :value="datadatapartlist.id"
                    >
                      {{ datadatapartlist.part_no }} - ({{
                        datadatapartlist.part_name
                      }})
                    </option>
                  </select>
                  <input
                    type="hidden"
                    class="form-control"
                    v-model="parts"
                    name=""
                    id=""
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1"
                    >Type Production Process</label
                  >
                  <select
                    @change="batchcodekombinasi($event)"
                    v-model="proseskd"
                    class="form-control"
                  >
                    <!-- <option disabled>--Please Select--</option> -->
                    <option
                      v-for="dataproseslist in proseslist"
                      :key="dataproseslist.id"
                      :value="dataproseslist.kode_proses"
                    >
                      {{ dataproseslist.kode_proses }}
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Batch Code</label>
                  <input
                    type="text"
                    readonly
                    class="form-control"
                    v-model="nomorproduksi"
                    name=""
                    id=""
                  />
                  <input
                    type="hidden"
                    class="form-control"
                    v-model="idbuyerorder"
                    name=""
                    id=""
                  />
                  <input
                    type="hidden"
                    class="form-control"
                    v-model="producnumb"
                    name=""
                    id=""
                  />
                  <input
                    type="hidden"
                    class="form-control"
                    v-model="ordrnumb"
                    name=""
                    id=""
                  />
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="col-md-6">
          <div class="box box-primary">
            <div class="box-header with-border">
              <i class="fa fa-save"></i>
              <h3 class="box-title">Save Production Process</h3>
            </div>
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Result Qty</label>
                  <input
                    @keyup="calc($event.target.value)"
                    type="number"
                    class="form-control"
                    v-model="qtyres"
                    name=""
                    id="qtyres"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Notes</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="catatanpp"
                    name=""
                    id=""
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Process Date</label>
                  <input
                    type="date"
                    class="form-control"
                    v-model="tglproses"
                    name=""
                    id=""
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="box box-primary">
            <div class="box-header">
              <i class="fa fa-list"></i>
              <h3 class="box-title">List Data Material From Warehouse</h3>
            </div>

            <!-- /.box-header -->
            <div v-if="isExist" class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Outgoing Number</th>
                      <th>Order Number</th>
                      <th>Product Name</th>
                      <th>Item Code</th>
                      <th>Stok</th>
                      <th>Qty</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(classdata, index) in list_paged3"
                      :key="classdata.id"
                    >
                      <td>{{ index + 1 + (page - 1) * pageSize }}</td>
                      <td>{{ classdata.outgoing_number }}</td>
                      <td>{{ classdata.order_number }}</td>
                      <td>{{ classdata.nama_produk }}</td>
                      <td>{{ classdata.kode_barang }}</td>
                      <td>{{classdata.stok}}</td>
                      <td>{{classdata.jumlahinp}}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div v-else class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Outgoing Number</th>
                      <th>Order Number</th>
                      <th>Product Name</th>
                      <th>Item Code</th>
                      <th>Stok</th>
                      <th>Qty</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colspan="6" class="text-center">
                          <b style="color:red"> {{ pesan }} </b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- /.box-body -->
          </div>
          <button type="button" @click="savedata99()" class="btn btn-info">
            <i class="fa fa-save"></i> Save
          </button>
          <br /><br />
        </div>

        <div class="col-md-6">
          <div class="box box-primary">
            <div class="box-header">
              <i class="fa fa-list"></i>
              <h3 class="box-title">List Data Other Production Parts</h3>
            </div>

            <!-- /.box-header -->
            <div class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Batch Code</th>
                      <th>Process Result</th>
                      <th>Stok</th>
                      <th>Qty</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(classdata2, index) in list_paged2"
                      :key="classdata2.id"
                    >
                      <td>{{ index + 1 + (page - 1) * pageSize }}</td>
                      <td>{{ classdata2.nomor_produksi }}</td>
                      <td>{{ classdata2.hasil_proses }}</td>
                      <td>{{classdata2.stok}}</td>
                      <td>{{classdata2.inpjml}}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- /.box-body -->
          </div>
        </div>
      </div>

      <!-- /.row -->
      <!-- Main row -->
    </section>
  </div>
</template>

<script>
// import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
// import { mapState } from 'vuex'
import swal from "sweetalert";
import axios from "axios";

export default {
  name: "addsum",

  /* computed: mapState([
    'datapartlist'
  ]), */
  // components: {
  //   VPagination
  // },
  data() {
    return {
      isShow: false,
      isExist: true,
      loading: false,
      namesearch: "",
      namesearch2: "",
      namesearch9: "",
      list_paged3: [],
      list_paged2: [],
      page: 1,
      page2: 1,
      page9: 1,
      pagestat: 1,
      count: 1,
      count2: 1,
      datalist: [],
      datalistidob: [],
      datapartlist: [],
      pageSize: 10,
      pageSize2: 10,
      pageSize9: 10,
      pageSizes: [10, 50, 100, 500],
      pageSizes2: [10, 50, 100, 500],
      pageSizes9: [10, 50, 100, 500],
      nomorproduk: "",
      producnumb: "",
      ordrnumb: "",
      partlists: "",
      proseskd: "",
      proseslist: [],
      nomorproduksi: "",
      qtyres: "",
      catatanpp: "",
      parts: "",
      tglproses: "",
      jumlahinp: "",
      inpjml: "",
      idbuyerorder: "",
      pesan: "",
      classdata: {
        jumlahinp: "",
        id: ""
      },
      classdata2: {
        inpjml: "",
        id: ""
      }
    };
  },
  /* mounted () {
    // console.log(this.$store);
    // this.$store.dispatch('loadSourceWH',this.datapartlist)
    // this.datalist = this.$store.getters.getdata
  }, */
  created() {
    this.produklist();
    this.getnow();
  },

  methods: {
    getnow() {
      const today = new Date();
      const tahun = today.getFullYear();
      var month = String(today.getMonth()+1).padStart(2,'0');
      var tgl = String(today.getDate()).padStart(2,'0');
      this.tglproses = tahun + "-" + month + "-" + tgl;
    },
    
    produklist() {
      this.loading = true;
      const tokenlogin = sessionStorage.getItem("token");
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      const urlApiGETdetail =
        this.$apiurl + "buyer_order/getdaftar_buyer_order_list";
      axios
        .get(urlApiGETdetail, { headers: headers })
        .then((resp) => {
          this.datalist = resp.data.data;
          // this.proseslist = resp.data.data;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err.response);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          });
        });
    },
    sumberwh(){
      this.isExist = false
      this.pesan = '......'
    },

    //ubah view detail jadi view part
    viewPart() {
      this.loading = true;
      // cari index no order
      var idorder = this.datalist.findIndex(p => p.id == this.nomorproduk);
      // cari nomor produk
      this.idbuyerorder = this.datalist[idorder].id;
      this.producnumb = this.datalist[idorder].nomor_produk;
      this.ordrnumb = this.datalist[idorder].order_number;
      
      const tokenlogin = sessionStorage.getItem("token");
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };

      const urlAPIget =
        this.$apiurl +
        "produk_d/getalldataproduk_d?page=1&length=5000&nomor_produk=" +
        this.producnumb;
      axios.get(urlAPIget, { headers: headers }).then((respo) => {
        this.datapartlist = respo.data.data;
        this.partlists = respo.data.data.part_no;
      });
      this.loading = false;
      this.batchcodekombinasi();
    },

    // show proses list
    viewProses() {
      this.loading = true;
      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIget =
        this.$apiurl +
        "produk_d_proses/getalldataproduk_d_proses_per_dept_user?page=1&length=5000&id_produk_d=" +
        this.partlists+"&kode_user=" + sessionStorage.getItem('kodeuser');

      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          this.proseslist = resp.data.data;
        })
        .catch((err) => {
          console.log(err.response);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          });
        });
        // inject part name
        var idpart=this.datapartlist.findIndex(p => p.id == this.partlists);
        this.parts=this.datapartlist[idpart].part_no;
        this.batchcodekombinasi();
    },
    // end show proses list

    batchcodekombinasi() {
      this.nomorproduksi ="";
      const today = new Date();
      const tahun = today.getFullYear();
      const month = String(today.getMonth()+1).padStart(2,"0");
      const tgl = String(today.getDate()).padStart(2,"0");
      this.nomorproduksi= tahun + month + tgl + this.parts + this.proseskd
      this.showGrid();
    },


    // show grid material and part
    showGrid(){
      const headers = {
        Accept: "application/json",
        Authorization: sessionStorage.token
      };
      const urlapigetmaterial =
        this.$apiurl + "proses_produksi_d_material/getalldatastok_material_di_produksi_basedon_order_part_proses?page=0&length=5000&cari=&order_number="+this.ordrnumb+"&part_no="+ this.parts+"&kode_proses="+this.proseskd ;
      axios
            .get(urlapigetmaterial, { headers: headers })
            .then((res) => {
              this.isExist=true;
              this.list_paged3=res.data.data;
            })
            .catch((err) => {
              this.isExist=false;
              console.log(err.response);
              swal({
                icon: "warning",
                title: "Warning",
                text: err.response
              });
            });
      const urlapigetpart =
        this.$apiurl + "proses_produksi_d_dari_proses_produksi/getalldatastok_hasil_produksi_berdasarkan_proses?page=0&length=5000&cari=&order_number="+this.ordrnumb+"&part_no="+ this.parts+"&kode_proses="+this.proseskd ;
      axios
            .get(urlapigetpart, { headers: headers })
            .then((res) => {
              this.list_paged2=res.data.data;
            })
            .catch((err) => {
              console.log(err.response);
              swal({
                icon: "warning",
                title: "Warning",
                text: err.response
              });
            });
              this.loading = false;
        this.calc();
    },

    savedata99() {
      const kodeuser = sessionStorage.getItem("kodeuser");
      const tokenlogin = sessionStorage.getItem("token");
      const paramdataHeader = {
        nomor_produksi: this.nomorproduksi,
        kode_proses: this.proseskd,
        notes: this.catatanpp,
        tanggal_proses: this.tglproses,
        kode_user: kodeuser,
        hasil_proses: this.qtyres,
        part_no: this.parts,
        id_buyer_order: this.idbuyerorder
      };
      this.loading=true;
      const urlAPISaveHeader =
        this.$apiurl + "proses_produksi/saveproses_produksi";
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };

      axios
        .post(urlAPISaveHeader, paramdataHeader, { headers })
        .then((resp) => {
          if (resp.data.status == true) {
            swal({
              icon: "success",
              title: "Success",
              text: "Save data successfully",
              showConfirmButton: false
            });
          } else {
            swal({
              icon: "warning",
              title: "Warning",
              text: "Save data failed",
              showConfirmButton: false
            });
          }
        })
        .catch((err) => {
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data,
            showConfirmButton: false
          });
        });
      //koding udin
      this.list_paged3.forEach(this.savewh);
      this.list_paged2.forEach(this.savepart);
      this.loading = false;
      // this.$router.push({ name: "prosesproduksiheader" });
    },
    savewh(data){
        const paramdataWH = {
            nomor_produksi: this.nomorproduksi,
            id_outgoing_d: data.id,
            notes: "",
            qty: data.jumlahinp,
            kode_user: sessionStorage.kodeuser
          };
          // console.log(paramdataWH);
          const tokenlogin = sessionStorage.getItem("token");
          const urlAPISaveDataWH =
            this.$apiurl +
            "proses_produksi_d_material/saveproses_produksi_d_material";
  
          const headers = {
            Accept: "application/json",
            Authorization: tokenlogin
          };
          if (Number(paramdataWH.qty) > 0) {
            axios
              .post(urlAPISaveDataWH, paramdataWH, { headers })
              .then((resp) => {
                if (resp.data.status == true) {
                  swal({
                    icon: "success",
                    title: "Success",
                    text: "Save data successfully",
                    showConfirmButton: false
                  });
                  this.loading = false;
                } else {
                  swal({
                    icon: "warning",
                    title: "Warning",
                    text: "Save data failed",
                    showConfirmButton: false
                  });
                  this.loading = false;
                }
              })
              .catch((err) => {
                swal({
                  icon: "warning",
                  title: "Warning",
                  text: err.response.data,
                  showConfirmButton: false
                });
                console.log(err);
                this.loading = false;
              });
          }
    },
    savepart(data){
        const paramdataprod = {
            nomor_produksi: data.nomor_produksi,
            id_proses_produksi: data.id,
            notes: "",
            qty: data.inpjml,
            kode_user: sessionStorage.kodeuser
        };
  
          if (Number(paramdataprod.qty) > 0) {
            const tokenloginn = sessionStorage.getItem("token");
            const urlApiSaveDataProd =
              this.$apiurl +
              "proses_produksi_d_dari_proses_produksi/saveproses_produksi_d_dari_proses_produksi";
  
            const headers = {
              Accept: "application/json",
              Authorization: tokenloginn
            };
            axios
              .post(urlApiSaveDataProd, paramdataprod, { headers })
              .then((resp) => {
                if (resp.data.status == true) {
                  swal({
                    icon: "success",
                    title: "Success",
                    text: "Save data successfully",
                    showConfirmButton: false
                  });
                  this.loading = false;
                } else {
                  swal({
                    icon: "warning",
                    title: "Warning",
                    text: "Save data failed",
                    showConfirmButton: false
                  });
                  this.loading = false;
                }
              })
              .catch((err) => {
                swal({
                  icon: "warning",
                  title: "Warning",
                  text: err.response.data,
                  showConfirmButton: false
                });
                console.log(err);
                this.loading = false;
              });
          }
    },
    calc(value){
      // this.list_paged2[idpart].inpjml=value;
      for (const data of this.list_paged3) {
        data.jumlahinp=value*data.total_kebutuhan;
        if (data.jumlahinp>data.stok) {
          swal({
                  icon: "warning",
                  title: "Warning",
                  text: "Quantity result exceed the amount of material",
                  showConfirmButton: false
                });
          this.qtyres="";
          this.calc(0);
        }
      }
      for (const data of this.list_paged2) {
        data.inpjml=value*data.total_kebutuhan; 
        if (data.inpjml>data.stok) {
          swal({
                  icon: "warning",
                  title: "Warning",
                  text: "Quantity result exceed the amount of part",
                  showConfirmButton: false
                });
          this.qtyres="";
          this.calc(0);
        }       
      }
    }
  }
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
